import React from 'react';
import { createStyles, withStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Theme } from '@material-ui/core/styles';

const styles = ({ palette }: Theme) => createStyles({
    button: {
        border: 'none',
        fontFamily: "Sarabun",
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '14px',
        color: '#FFFFFF',
        textAlign: 'center',
        textDecoration: 'none',
        margin: '5px 5px',
        padding: '10px',
        borderRadius: '5px',
        direction: 'ltr',
        '&:hover': {
            cursor: 'pointer',
        }
    },
    primary: {
        backgroundColor: palette.primary.main,
        '&:hover': {
            backgroundColor: palette.primary.dark,
        }
    },
    disabled: {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
    secondary: {
        backgroundColor: palette.secondary.main,
        '&:hover': {
            backgroundColor: palette.secondary.dark,
        }
    },
    tertiary: {
        backgroundColor: '#985CA6',
        '&:hover': {
            backgroundColor: '#844797',
        }
    },
    accept2: {
        backgroundColor: '#0797fd',
        '&:hover': {
            backgroundColor: palette.primary.main
        }
    },
    send: {
        backgroundColor: '#00CE88',
        '&:hover': {
            backgroundColor: '#00A56D',
        }
    },
    whatsApp: {
        backgroundColor: "#25D366",
        '&:hover': {
            backgroundColor: '#1ebea5',
        }
    },
    success: {
        backgroundColor: '#26C281',
        '&:hover': {
            backgroundColor: '#057759',
        }
    },
    information: {
        backgroundColor: '#00CCFF',
        '&:hover': {
            backgroundColor: '#1364C9',
        }
    },
    warning: {
        backgroundColor: '#F79E1E',
        '&:hover': {
            backgroundColor: '#BC721B',
        }
    },
    danger: {
        backgroundColor: '#CE4861',
        '&:hover': {
            backgroundColor:'#8E1C38',
        }
    },
    dark: {
        backgroundColor: '#2D3E50',
        '&:hover': {
            backgroundColor: '#1A252D',
        }
    },
    light: {
        backgroundColor: '#FFFFFF',
        border: '#56617F solid 1px',
        color: '#2D3E50',
        '&:hover': {
            backgroundColor: '#CCCCCC',
        }
    },
    cancel: {
        backgroundColor: 'white',
        color: '#044C9B',
        borderColor: '#044C9B',
        borderStyle: 'solid'
    },
    accept: {
        backgroundColor: '#044C9B',
        color: 'white',
        borderColor: 'white', 
    },

    recharge: {
        backgroundColor: '#36c1ff',
        color: 'white',
    },

    detail: {
        backgroundColor: '#044C9B',
        color: 'white',
    },
    large: {
        width: "200px"
    },
    medium: {
        width: "135px"
    },
    small: {
        width: "95px"
    },
    slim: {
        padding: '8px 18px'
    },
    icon: {
        width: "95px",
        marginRight: '8px',
        fontSize: '16px'
    }
});

interface ButtonProps {
    onClick: (event: React.SyntheticEvent<any>, reason: string) => void;
    size: 'large' | 'medium' | 'small' | 'slim';
    type: "primary" | "secondary" | "tertiary" | "accept2" | "disabled" | "success" | "information" | "warning" | "danger" | "dark" | "light" | "cancel" | "accept" | "detail" | "recharge" | "send" | "whatsApp";
    text: string;
    icon?: any;
    style?: {};
    disabled? : boolean;
    classes: any;
}


export const Button = ({ icon, size, type, text, onClick, style, classes, disabled }: ButtonProps) => {

    return (
        <button className={`${classes.button} ${classes[type]} ${size ? classes[size] : "large"}`} onClick={(event) => onClick(event, 'click')} style={style} disabled={disabled}>
            {icon && <FontAwesomeIcon icon={icon} className={classes.icon} />}
            {text}
        </button>
    )
}

export default withStyles(styles)(Button);
