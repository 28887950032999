import { createStore, applyMiddleware, MiddlewareAPI, AnyAction } from 'redux';
import reducers from './ducks';
import thunk from 'redux-thunk';
import { setToken } from '../apiV2';
import _ from "lodash";
import { composeWithDevTools } from 'redux-devtools-extension';
import { Dispatch } from 'react';

const saveAuthToken = (store: MiddlewareAPI<any, {}>) => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
    if (action.type === 'FETCH_USER_INFO') {
        const token = _.get(action, 'user.token', undefined)
        // const idCliente = _.get(action, 'user.idCliente', undefined)
        if (token) setToken(token)
    }
    
    return next(action);
}

const composeEnhancers = composeWithDevTools({});

const store = createStore(
    reducers,   
    composeEnhancers(
        // applyMiddleware(saveAuthToken),
        applyMiddleware(thunk, saveAuthToken),
    )
);

export default store;
