import { AnyAction } from 'redux';
import { createReducer, createActions, DefaultActionTypes, DefaultActionCreators } from 'reduxsauce';
import { IContract } from './types';

export interface ISetCurrentContract extends AnyAction {
    currentContract: IContract;
}

interface IActionTypes extends DefaultActionTypes {
    SET_CURRENT_CONTRACT: 'setCurrentContract'
}

interface IActionCreators extends DefaultActionCreators {
    setCurrentContract: (currentContract: IContract) => ISetCurrentContract,
}

type IActions = ISetCurrentContract | AnyAction

export interface IStoreApiV2 {
    currentContract: IContract | undefined,
}

export const { Types, Creators } = createActions<IActionTypes, IActionCreators>({
    setCurrentContract: ['currentContract'],
})


const INITIAL_STATE: IStoreApiV2 = {
    currentContract: undefined,
}


export const setCurrentContract = (state: IStoreApiV2, { currentContract }: ISetCurrentContract): IStoreApiV2 => {
    return { ...state, currentContract }
}

const reducer = createReducer<IStoreApiV2, IActions>(INITIAL_STATE, {
    [Types.SET_CURRENT_CONTRACT]: setCurrentContract
})

export default reducer;