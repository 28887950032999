import React from 'react';
import { createStyles, withStyles } from '@material-ui/styles';

const styles = ({ palette }: any) => createStyles({
    title: {
        fontFamily: "Sarabun",
        fontWeight: 'lighter',
        textTransform: 'initial',
        fontSize: '12px',
        color: '#000000',
        marginTop: '10px'
    },
    description: {
        fontFamily: "Sarabun",
        fontWeight: 'lighter',
        textTransform: 'initial',
        fontSize: '14px',
        color: '#4D4D4D;',
        marginBottom: '10px'
    },
    placeholder: {
        fontFamily: "Sarabun",
        fontWeight: 'lighter',
        textTransform: 'initial',
        fontSize: '10px',
        color: '#4D4D4D;',
        verticalAlign: "middle"
    },
    input: {
        width: "100%",
        padding: "15px 15px",
        /* margin: "10px 0px", */
        marginTop: "5px",
        borderRadius: "5px",
        border: "solid 1px #56617F",
        borderWidth: "thin",
        boxSizing: "border-box"
    },
    container: {
        margin: "5px 10px",
    }
});

interface InputProps {
    title: string;
    colorTitle?: string;
    placeholder: string;
    description?: string | null;
    colorDescription?: string;
    disabled?: boolean;
    classes: any;
    onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
    onKeyUp?: (event: React.FormEvent<HTMLInputElement>) => void;
    value?: string;
    maxLength?: number;
    readOnly?: boolean;
    type?: string;
}

export const Input = ({ type, description, colorDescription, disabled = false, placeholder, title, colorTitle, onChange, onKeyUp, classes, value, maxLength, readOnly }: InputProps) => {
    return (
        <div className={classes.container}>
            <span style={{color: colorTitle }} className={classes.title}>{title}</span>
            <input type={type ? type : 'text'} readOnly={readOnly} maxLength={maxLength} value={value} disabled={disabled} className={`${classes.input}`} onChange={onChange} onKeyUp={onKeyUp} placeholder={placeholder} />
            {description && <span style={{color: colorDescription && colorDescription }}className={classes.description}>{description}</span>}
        </div>
    )
}

export default withStyles(styles)(Input);
