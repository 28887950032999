import React, { useEffect } from 'react';
import { createStyles, Theme } from '@material-ui/core/styles';
import { Box, makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Dispatch } from "redux";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { /*LoginRedux,*/ AlertRedux } from 'megacable-mobile-library';
// import { getStoreV2 } from '../storeV2/store';
import _ from 'lodash';

export const notifyConnectionError = () => {
    // let store = getStoreV2();
    // store.dispatch(AlertRedux.showAlert("¡Upps! lamentamos las molestias, intente más tarde.", "Conexión"));
}

// export const isValidToken = (message: any) => {
//     let store = getStoreV2();
//     if (message && message === "invalid token") {
//         store.dispatch(AlertRedux.showAlert("La sesión ha caducado.", "SESION"));
//         store.dispatch(LoginRedux.logOut(()=>{}));
//         // AsyncStorage.removeItem('token');
//         // this.props.logOut();
//     }
// }

type PropsFromRedux = ConnectedProps<typeof connector>

type IAlertDialog = PropsFromRedux & {
    onPressHide?: () => void;
}

const AlertDialogLoginScreen = (props: IAlertDialog) => {
    const { alert } = props;
    const classes = styles();
    const [alertMessage, setAlertMessage] = React.useState<string>('');
    const [alertTitle, setAlertTitle] = React.useState<string>('');
    const [showAlert, setShowAlert] = React.useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (alert) {
            if (_.hasIn(alert, ['alertMessage']) && _.hasIn(alert, ['alertTitle']) && _.hasIn(alert, ['showAlert'])) {
                setAlertMessage(alert.alertMessage);
                setAlertTitle(alert.alertTitle);
                setShowAlert(alert.showAlert);
            }
        }
        return () => { }
    }, [alert]);

    const hideAlert = () => {
        if (props.onPressHide) {
            props.onPressHide();
        }
        dispatch(AlertRedux.hideAlert());
    }

    return (
        <div className={classes.root}>
            <Dialog
                open={showAlert}
                onClose={hideAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {alertTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {alertMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={hideAlert} color="primary">
                    Disagree
                </Button> */}
                    <Button className={classes.buttonFinish} onClick={hideAlert} autoFocus>
                        <Typography component={'span'} variant="body1">
                            <Box fontWeight={700} color="white">Ok</Box>
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const styles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        borderColor: "#b7b7b7",
        borderTopWidth: 1,
        borderTopStyle: "solid",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        cursor: "pointer",
    },
    buttonFinish: {
        justifySelf: "center",
        backgroundColor: "#0797fd",
        borderRadius: 5,
        border: 0,
        padding: 8,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "#fff"
        },
        "& .MuiTouchRipple-root span": {
            backgroundColor: "#6fc3fc",
        },
    },
}));

const mapState = (state: any) => ({
    alert: state.alert
});

const mapDispatch = (dispatch: Dispatch<any>) => ({

});

const connector = connect(mapState, mapDispatch)

export default (connector(AlertDialogLoginScreen));
